import React from 'react'
import { Heading, Box } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  wrapper: {
    ml: 3
  },
  heading: {
    color: `alpha`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`
  }
}

export default () => (
  <Box sx={styles.wrapper}>
    <Heading variant='h1' sx={styles.heading}>
      Already set up to resolve Handshake domains?
    </Heading>
    <Heading variant='h2' sx={styles.running}>
      Then why not access our site via our Handshake domain http://namecity/? If you've set up DANE too then use https://namecity/.
    </Heading>
  </Box>
)
